import { utcToZonedTime } from "date-fns-tz";
import { isArray } from "lodash";
import { useSnackbar } from "notistack";
import { type ReactNode, createContext, useEffect, useState } from "react";
import {
	generatePath,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import type { SiteContent } from "src/@types/SiteContent";
import { ElectricityTypes } from "src/@types/charts";
import type { meteringplanBaseObject } from "src/@types/installation";
import type { MagicButtonState } from "src/@types/magic";
import type { SiteItem } from "src/@types/sites";
import { brandSetting } from "src/config";
import useAuth from "src/hooks/useAuth";
import useBenchmarkContext from "src/hooks/useBenchmarkContext";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
	alertsLastSeen,
	getAlerts,
	getAlertsNotification,
} from "src/services/service";
import {
	corporateLimit as corporateLimitFn,
	isAllMySitesFn,
} from "src/utils/constants";
import { capitalizeFirstLetter } from "src/utils/text";
import { convertSeconds } from "src/utils/timeFormatter";
import { t } from "ttag";
import type { Alert, AlertNotification, TimeConditions } from "../@types/alert";
import { buildBasicState } from "./magicButtonContext";

type AlertsProviderProps = { children: ReactNode };

export enum AlertTab {
	all = "All",
	active = "Active",
	inactive = "Inactive",
}

export type AlertsContextProps = {
	unseenAlerts: number;
	seenDatesPerInstall: null | Record<string, string>;
	unseenPerSite: null | Record<string, number>;
	alerts: Array<Alert>;
	notifsList: Array<AlertNotification>;
	notifsForAlert: Array<AlertNotification>;
	notifsCount: number | null;
	notifsForAlertCount: number | null;
	setNotifsForAlertCount: (count: number | null) => void;
	openNotifDetail: boolean;
	setOpenNotifDetail: (open: boolean) => void;
	alertsLoading: boolean;
	notifsLoading: boolean;
	alertTab: AlertTab;
	setAlertTab: (tab: AlertTab) => void;
	unseenStatus: "initial" | "needs put" | "done";
	loadNextPage: (setPageLoading?: (isLoading: boolean) => void) => void;
	loadSiteAlerts: (site_uuid: string, reset: boolean) => void;
	alertsIsReady: boolean;
};

const initialState: AlertsContextProps = {
	unseenAlerts: 0,
	seenDatesPerInstall: {},
	unseenPerSite: {},
	alerts: [],
	notifsList: [],
	notifsForAlert: [],
	notifsCount: null,
	notifsForAlertCount: null,
	setNotifsForAlertCount: () => {},
	openNotifDetail: false,
	setOpenNotifDetail: () => {},
	alertsLoading: false,
	notifsLoading: false,
	alertTab: AlertTab.all,
	setAlertTab: () => {},
	unseenStatus: "initial",
	loadNextPage: () => {},
	loadSiteAlerts: () => {},
	alertsIsReady: false,
};

const AlertsContext = createContext(initialState);

function AlertProvider({ children }: AlertsProviderProps) {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useAuth();
	const { Site, siteLoading } = useSiteContext();
	const { MultiSites, nodesLoaded, MultisiteAlertsAccess, MultisitesCount } =
		useNodesContext();

	const [unseenAlerts, setUnseenAlerts] = useState<number>(0);
	const [seenDatesPerInstall, setSeenDatesPerInstall] = useState<null | Record<
		string,
		string
	>>({});
	const [unseenPerSite, setUnseenPerSite] = useState<null | Record<
		string,
		number
	>>({});
	const [alerts, setAlerts] = useState<Array<Alert>>([]);
	const [notifsList, setNotifsList] = useState<Array<AlertNotification>>([]);
	const [notifsForAlert, setNotifsForAlert] = useState<
		Array<AlertNotification>
	>([]);
	const [notifsCount, setNotifsCount] = useState<number | null>(null);
	const [notifsForAlertCount, setNotifsForAlertCount] = useState<number | null>(
		null,
	);
	const [openNotifDetail, setOpenNotifDetail] = useState(false);
	const [notifPage, setNotifPage] = useState(1);
	const [alertsLoading, setAlertsLoading] = useState(false);
	const [notifsLoading, setNotifsLoading] = useState(false);
	const [alertTab, setAlertTab] = useState<AlertTab>(AlertTab.all);
	const [alertsIsReady, setAlertsIsReady] = useState(false);
	const [unseenStatus, setUnseenStatus] = useState<
		"initial" | "needs put" | "done"
	>("initial");
	const isAlertDetails = params.alert_id !== undefined;
	const needs_to_load = isAlertDetails && notifsForAlertCount === null;

	const { hasBenchmark } = useBenchmarkContext();

	const joinNotif = (notifs_to_add: Array<AlertNotification>) => {
		setNotifsList(notifsList.concat(notifs_to_add));
	};

	const joinNotifForAlert = (notifs_to_add: Array<AlertNotification>) => {
		setNotifsForAlert(notifsForAlert.concat(notifs_to_add));
	};

	useEffect(() => {
		if (
			params.node &&
			Site !== undefined &&
			params.node === Site?.uuid &&
			!siteLoading
		) {
			setUnseenStatus("initial");
			setNotifsLoading(true);
			setAlertsLoading(true);
			setNotifsCount(null);
			setAlertsIsReady(false);
			setNotifPage(1);
		}
	}, [Site?.uuid, siteLoading]);

	useEffect(() => {
		if (
			params.customer &&
			MultiSites !== undefined &&
			hasBenchmark &&
			nodesLoaded
		) {
			setUnseenStatus("initial");
			setNotifsLoading(true);
			setAlertsLoading(true);
			setNotifsCount(null);
			setAlertsIsReady(false);
			setNotifPage(1);
		}
	}, [params.customer, MultisitesCount, nodesLoaded]);

	useEffect(() => {
		if (!nodesLoaded) return;

		if (
			params.node === undefined &&
			params.customer !== undefined &&
			params.node &&
			Site !== undefined &&
			!siteLoading
		) {
			setUnseenStatus("initial");
			setNotifsLoading(true);
			setAlertsLoading(true);
			setNotifsCount(null);
			setAlertsIsReady(false);
			setNotifPage(1);
		}
		if (
			(params.node && params.node !== Site?.uuid) ||
			(params.customer &&
				MultiSites &&
				!isArray(MultiSites) &&
				params.customer !== MultiSites.uuid) ||
			(isArray(MultiSites) && params.customer !== "all")
		) {
			setUnseenStatus("initial");
			setNotifsLoading(true);
			setAlertsLoading(true);
			setNotifsCount(null);
			setNotifsList([]);
			setAlertsIsReady(false);
			setNotifPage(1);
		}
	}, [params.customer, params.node, location.pathname, nodesLoaded]);

	/// ALERT SITE MONO !
	useEffect(() => {
		if (!nodesLoaded || !brandSetting.withAlerts) return;
		if (
			!params.customer &&
			params.node &&
			Site !== undefined &&
			params.node === Site.uuid &&
			Site.has_alerts_access &&
			notifsLoading &&
			alertsLoading
		) {
			getAlertsNotification(Site.uuid)
				.then((response) => {
					setNotifsCount(response.data.count);
					setNotifsList(response.data.results);
					setNotifsLoading(false);
				})
				.catch((_error) => {
					enqueueSnackbar(
						t`An error occurred when retrieving alerts. Please try again.`,
						{ variant: "error" },
					);
				});

			getAlerts(Site.uuid)
				.then((response) => {
					const alerts: Array<Alert> = response.data;
					alerts.map((alert: Alert) => {
						const { hours, minutes } = convertSeconds(
							Number.parseInt(alert.duration_threshold),
						);
						alert.duration_hour = hours;
						alert.duration_min = minutes;
					});
					setAlerts(alerts);
					setAlertsLoading(false);
					if (isAlertDetails) {
						const alert = alerts.find(
							(item) => item.id.toString() === params.alert_id,
						);
						if (alert === undefined) {
							// wrong combination node / alert id in url
							navigate(
								generatePath(PATH_DASHBOARD.alerts.list_with_node, {
									node: Site.uuid,
								}),
							);
						}
					}
				})
				.catch((_error) => {
					enqueueSnackbar(
						t`An error occurred when retrieving alerts. Please try again.`,
						{ variant: "error" },
					);
				});
		}
	}, [Site?.uuid, siteLoading, notifsLoading, alertsLoading]);

	useEffect(() => {
		if (siteLoading || !brandSetting.withAlerts) return;

		if (
			!params.customer &&
			params.node &&
			Site !== undefined &&
			params.node === Site.uuid &&
			Site.has_alerts_access &&
			!notifsLoading &&
			!alertsLoading &&
			unseenStatus === "initial"
		) {
			alertsLastSeen(Site.uuid, "get")
				.then((response) => {
					setUnseenAlerts(response.data.unseen_count);
					setSeenDatesPerInstall(response.data.seen_date_per_installation);
					setUnseenPerSite(response.data.unseen_per_site);
					setUnseenStatus("needs put");
				})
				.catch((_error) => {
					enqueueSnackbar(
						t`An error occurred when retrieving alerts. Please try again.`,
						{ variant: "error" },
					);
				});
		}
	}, [siteLoading, Site?.uuid, notifsLoading, alertsLoading]);

	useEffect(() => {
		if (siteLoading || !brandSetting.withAlerts) return;

		if (
			!params.customer &&
			params.node &&
			Site !== undefined &&
			params.node === Site.uuid &&
			Site.has_alerts_access &&
			location.pathname.includes("alerts") &&
			unseenStatus === "needs put"
		) {
			alertsLastSeen(Site.uuid, "put")
				.then((response) => {
					if (response) setUnseenStatus("done");
				})
				.catch((error) => {
					console.error(error);
				});
		} else if (
			unseenStatus === "done" &&
			!location.pathname.includes("alerts")
		) {
			setUnseenAlerts(0);
			setSeenDatesPerInstall({});
			setUnseenPerSite({});
		}
	}, [siteLoading, Site?.uuid, location.pathname, unseenStatus]);

	useEffect(() => {
		// reload notifications for alerts
		if (siteLoading || !brandSetting.withAlerts) return;
		if (
			needs_to_load &&
			isAlertDetails &&
			Site !== undefined &&
			params.node === Site.uuid &&
			Site.has_alerts_access &&
			!notifsLoading
		) {
			setNotifsLoading(true);
			getAlertsNotification(Site.uuid, params.alert_id)
				.then((response) => {
					setNotifsForAlert(response.data.results);
					setNotifsForAlertCount(response.data.count);
					setNotifsLoading(false);
				})
				.catch((_error) => {
					enqueueSnackbar(
						t`An error occurred when retrieving alerts. Please try again.`,
						{ variant: "error" },
					);
				});
		}
	}, [siteLoading, needs_to_load, notifsLoading, Site?.uuid]);

	useEffect(() => {
		if (siteLoading || !brandSetting.withAlerts) return;

		if (
			params.notif_id !== undefined &&
			Site !== undefined &&
			Site.has_alerts_access &&
			notifsList.length > 0 &&
			!notifsLoading
		) {
			// notifsList.some(item => console.log(notifsList, 'notifsList notifsList'))
			const isNotif = notifsList.some(
				(item) => item.id.toString() === params.notif_id,
			);
			if (!isNotif && Site.uuid !== undefined) {
				// retrieve notification info from back if not in list
				getAlertsNotification(
					Site.uuid,
					undefined,
					undefined,
					params.notif_id,
					true,
				)
					.then((response) => {
						if (isAlertDetails) {
							joinNotifForAlert([response.data]);
						} else {
							joinNotif([response.data]);
						}
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});
			}
			setOpenNotifDetail(true);
		}
	}, [siteLoading, notifsList, notifsLoading, Site?.uuid]);

	useEffect(() => {
		if (
			!notifsLoading &&
			!alertsLoading &&
			notifsList &&
			alerts &&
			notifsCount !== null
		) {
			// if (notifsList.length === 0 || alerts.length === 0) return
			setAlertsIsReady(true);
		}
	}, [alerts, notifsList]);

	/// ALERT MULTISITE !
	useEffect(() => {
		if (!nodesLoaded || !brandSetting.withAlerts) return;
		if (
			params.customer !== "all" &&
			MultiSites &&
			!params.node &&
			MultisiteAlertsAccess &&
			(MultiSites as SiteItem).uuid &&
			notifsLoading &&
			alertsLoading
		) {
			if (params.customer === (MultiSites as SiteItem).uuid) {
				getAlertsNotification(
					(MultiSites as SiteItem).uuid,
					undefined,
					undefined,
					undefined,
					undefined,
					(MultiSites as SiteItem).type,
				)
					.then((response) => {
						setNotifsCount(response.data.count);
						setNotifsList(response.data.results);
						setNotifsLoading(false);
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});

				getAlerts((MultiSites as SiteItem).uuid, (MultiSites as SiteItem).type)
					.then((response) => {
						const alerts: Array<Alert> = response.data;
						alerts.map((alert: Alert) => {
							const { hours, minutes } = convertSeconds(
								Number.parseInt(alert.duration_threshold),
							);
							alert.duration_hour = hours;
							alert.duration_min = minutes;
						});
						setAlerts(alerts);
						setAlertsLoading(false);
						if (isAlertDetails) {
							const alert = alerts.find(
								(item) => item.id.toString() === params.alert_id,
							);
							if (alert === undefined) {
								// wrong combination node / alert id in url
								navigate(
									generatePath(PATH_DASHBOARD.alerts.list_with_customer, {
										customer: (MultiSites as SiteItem).uuid,
									}),
								);
							}
						}
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});
			}
		} else if (
			user !== null &&
			user.uuid &&
			isAllMySitesFn(params) &&
			MultiSites &&
			isArray(MultiSites)
		) {
			if (MultisiteAlertsAccess && notifsLoading && alertsLoading) {
				getAlertsNotification(
					user.uuid,
					undefined,
					undefined,
					undefined,
					undefined,
					"user",
				)
					.then((response) => {
						setNotifsCount(response.data.count);
						setNotifsList(response.data.results);
						setNotifsLoading(false);
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});

				getAlerts(user.uuid, "user")
					.then((response) => {
						const alerts: Array<Alert> = response.data;
						alerts.map((alert: Alert) => {
							const { hours, minutes } = convertSeconds(
								Number.parseInt(alert.duration_threshold),
							);
							alert.duration_hour = hours;
							alert.duration_min = minutes;
						});
						setAlerts(alerts);
						setAlertsLoading(false);
						// if (isAlertDetails) {
						//     const alert = alerts.find(item => item.id.toString() === params.alert_id);
						//     if (alert == undefined) {
						//         // wrong combination node / alert id in url
						//         navigate(generatePath(PATH_DASHBOARD.alerts.list_with_customer, { customer: (MultiSites as SiteItem).uuid }))
						//     }
						// }
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});
			}
		}
	}, [MultisiteAlertsAccess, notifsLoading, alertsLoading, location.pathname]);

	useEffect(() => {
		if (!nodesLoaded || !brandSetting.withAlerts) return;
		if (
			params.customer !== "all" &&
			!params.node &&
			MultiSites &&
			MultisiteAlertsAccess &&
			(MultiSites as SiteItem).uuid &&
			!notifsLoading &&
			!alertsLoading &&
			unseenStatus === "initial"
		) {
			if (params.customer === (MultiSites as SiteItem).uuid) {
				alertsLastSeen(
					(MultiSites as SiteItem).uuid,
					"get",
					(MultiSites as SiteItem).type,
				)
					.then((response) => {
						setUnseenAlerts(response.data.unseen_count);
						setSeenDatesPerInstall(response.data.seen_date_per_installation);
						setUnseenPerSite(response.data.unseen_per_site);
						setUnseenStatus("needs put");
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});
			}
		} else if (
			user !== null &&
			user.uuid &&
			isAllMySitesFn(params) &&
			MultiSites &&
			isArray(MultiSites)
		) {
			if (
				MultisiteAlertsAccess &&
				!notifsLoading &&
				!alertsLoading &&
				unseenStatus === "initial"
			) {
				alertsLastSeen(user.uuid, "get", "user")
					.then((response) => {
						setUnseenAlerts(response.data.unseen_count);
						setSeenDatesPerInstall(response.data.seen_date_per_installation);
						setUnseenPerSite(response.data.unseen_per_site);
						setUnseenStatus("needs put");
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});
			}
		}
	}, [MultisiteAlertsAccess, notifsLoading, alertsLoading]);

	useEffect(() => {
		if (!nodesLoaded || !brandSetting.withAlerts) return;
		if (
			params.customer !== "all" &&
			!params.node &&
			MultiSites &&
			MultisiteAlertsAccess &&
			(MultiSites as SiteItem).uuid &&
			location.pathname.includes("alerts") &&
			unseenStatus === "needs put"
		) {
			alertsLastSeen(
				(MultiSites as SiteItem).uuid,
				"put",
				(MultiSites as SiteItem).type,
			)
				.then((_response) => {
					setUnseenStatus("done");
				})
				.catch((error) => {
					console.error(error);
				});
		} else if (
			params.customer !== "all" &&
			unseenStatus === "done" &&
			!location.pathname.includes("alerts")
		) {
			setUnseenAlerts(0);
			setSeenDatesPerInstall({});
			setUnseenPerSite({});
		}
		if (
			user !== null &&
			user.uuid &&
			isAllMySitesFn(params) &&
			MultiSites &&
			isArray(MultiSites)
		) {
			if (MultisiteAlertsAccess && notifsLoading && alertsLoading) {
				alertsLastSeen(user.uuid, "put", "user")
					.then((_response) => {
						setUnseenStatus("done");
					})
					.catch((error) => {
						console.error(error);
					});
			} else if (
				isAllMySitesFn(params) &&
				unseenStatus === "done" &&
				!location.pathname.includes("alerts")
			) {
				setUnseenAlerts(0);
				setSeenDatesPerInstall({});
				setUnseenPerSite({});
			}
		}
	}, [
		MultisiteAlertsAccess,
		location.pathname && params.customer,
		unseenStatus,
	]);

	const loadNextPage = (setPageLoading?: (isLoading: boolean) => void) => {
		if (
			(Site?.uuid !== undefined ||
				(isArray(MultiSites) && isAllMySitesFn(params)) ||
				(MultiSites &&
					!isArray(MultiSites) &&
					params.customer === MultiSites.uuid)) &&
			!notifsLoading &&
			((!isAlertDetails &&
				notifsCount !== null &&
				notifsList.length < notifsCount) ||
				(isAlertDetails &&
					notifsForAlertCount !== null &&
					notifsForAlert.length < notifsForAlertCount))
		) {
			if (setPageLoading !== undefined) setPageLoading(true);
			// load next page of notifications
			if (
				params.customer &&
				MultiSites &&
				((isArray(MultiSites) && isAllMySitesFn(params)) ||
					(!isArray(MultiSites) && params.customer === MultiSites.uuid))
			) {
				if (isArray(MultiSites) && isAllMySitesFn(params) && user) {
					getAlertsNotification(
						user.uuid,
						undefined,
						notifPage + 1,
						undefined,
						undefined,
						"user",
					)
						.then((response) => {
							if (isAlertDetails) {
								joinNotifForAlert(response.data.results);
							} else {
								joinNotif(response.data.results);
							}
							setNotifPage(notifPage + 1);
							if (setPageLoading !== undefined) setPageLoading(false);
						})
						.catch((_error) => {
							enqueueSnackbar(
								t`An error occurred when retrieving alerts. Please try again.`,
								{ variant: "error" },
							);
						});
				} else {
					getAlertsNotification(
						(MultiSites as SiteItem).uuid,
						undefined,
						notifPage + 1,
						undefined,
						undefined,
						(MultiSites as SiteItem).type,
					)
						.then((response) => {
							if (isAlertDetails) {
								joinNotifForAlert(response.data.results);
							} else {
								joinNotif(response.data.results);
							}
							setNotifPage(notifPage + 1);
							if (setPageLoading !== undefined) setPageLoading(false);
						})
						.catch((_error) => {
							enqueueSnackbar(
								t`An error occurred when retrieving alerts. Please try again.`,
								{ variant: "error" },
							);
						});
				}
			} else {
				getAlertsNotification(Site!.uuid, undefined, notifPage + 1)
					.then((response) => {
						if (isAlertDetails) {
							joinNotifForAlert(response.data.results);
						} else {
							joinNotif(response.data.results);
						}
						setNotifPage(notifPage + 1);
						if (setPageLoading !== undefined) setPageLoading(false);
					})
					.catch((_error) => {
						enqueueSnackbar(
							t`An error occurred when retrieving alerts. Please try again.`,
							{ variant: "error" },
						);
					});
			}
		}
	};

	const loadSiteAlerts = (site_uuid: string, reset: boolean) => {
		getAlertsNotification(
			site_uuid,
			undefined,
			undefined,
			undefined,
			undefined,
			"site",
		)
			.then((response) => {
				if (reset) setNotifsList(response.data.results);
				else joinNotif(response.data.results);
			})
			.catch((_error) => {
				enqueueSnackbar(
					t`An error occurred when retrieving alerts. Please try again.`,
					{ variant: "error" },
				);
			});
	};

	return (
		<AlertsContext.Provider
			value={{
				unseenAlerts,
				seenDatesPerInstall,
				unseenPerSite,
				alerts,
				notifsList,
				notifsForAlert,
				notifsCount,
				notifsForAlertCount,
				setNotifsForAlertCount,
				openNotifDetail,
				setOpenNotifDetail,
				alertsLoading,
				notifsLoading,
				alertTab,
				setAlertTab,
				unseenStatus,
				loadNextPage,
				loadSiteAlerts,
				alertsIsReady,
			}}
		>
			{children}
		</AlertsContext.Provider>
	);
}

const getLocalMagicButtonState = (
	alert: Alert,
	Site: SiteContent,
	installationObj: meteringplanBaseObject,
	start_date: Date,
	end_date: Date,
	threshold: number,
	alertPeriodDatesRange?: [Date, Date],
	timeConditions?: TimeConditions,
) => {
	let chartType: string = capitalizeFirstLetter(alert.source);

	if (alert.source === "energy") {
		chartType = chartType + capitalizeFirstLetter(alert.granularity);
	}

	const tz_start_date = utcToZonedTime(start_date.getTime(), Site.timezone);
	const tz_end_date = utcToZonedTime(end_date.getTime(), Site.timezone);

	const local_state: MagicButtonState = {
		...buildBasicState(installationObj, Site),
		minDate: tz_start_date,
		maxDate: tz_end_date,
		mainDates: { shortcut: undefined, dateRange: [tz_start_date, tz_end_date] },
		compareDates: {
			shortcut: undefined,
			dateRange: [tz_start_date, tz_end_date],
		},
		chartType: ElectricityTypes[chartType as keyof typeof ElectricityTypes],
		withUnoccupation: false,
		yAxis: "selection",
	};

	if (alertPeriodDatesRange) {
		local_state.alertPeriodDates = {
			shortcut: undefined,
			dateRange: [
				utcToZonedTime(alertPeriodDatesRange[0].getTime(), Site.timezone),
				utcToZonedTime(alertPeriodDatesRange[1].getTime(), Site.timezone),
			],
		};
	}

	if (timeConditions && timeConditions.time_start && timeConditions.time_end) {
		const start_active_date = new Date(tz_start_date);
		const end_active_date = new Date(tz_start_date);
		const start_active_time = timeConditions.time_start.split(":");
		const end_active_time = timeConditions.time_end.split(":");
		start_active_date.setHours(
			Number.parseInt(start_active_time[0]),
			Number.parseInt(start_active_time[1]),
			Number.parseInt(start_active_time[2]),
		);
		end_active_date.setHours(
			Number.parseInt(end_active_time[0]),
			Number.parseInt(end_active_time[1]),
			Number.parseInt(end_active_time[2]),
		);
		// start_active_date = utcToZonedTime(start_active_date.getTime(), Site.timezone)
		// end_active_date = utcToZonedTime(end_active_date.getTime(), Site.timezone)
		local_state.alertActiveDates = {
			shortcut: undefined,
			dateRange: [start_active_date, end_active_date],
		};
	}

	// no fixed threshold for week comparison
	if (alert.granularity !== "week") {
		local_state.alertThreshold = threshold;
	}

	if (alert.target !== "total") {
		const target_category = {
			id: Number.parseInt(alert.target),
			color: alert.target_color,
			label: alert.target_label,
		};
		local_state.categories = [target_category];
		local_state.category = Number.parseInt(alert.target);
		local_state.categoryLabel = alert.target_label;
	}
	return local_state;
};

export { AlertProvider, AlertsContext, getLocalMagicButtonState };
