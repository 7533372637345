import { isArray } from "lodash";
import { useSnackbar } from "notistack";
import {
	type ReactNode,
	createContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import type { meteringplanBenchmark } from "src/@types/installation";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
	corporateLimit as corporateLimitFn,
	isAllMySitesFn,
} from "src/utils/constants";
import { goToWithPeriod } from "src/utils/links";
import { returnChild } from "src/utils/treeFinder";
import { t } from "ttag";
import type { SiteItem, SitesList } from "../@types/sites";
import { getAll, getMultisites } from "../services/service";

type SitesProviderProps = {
	children: ReactNode;
};

export type NodesContextProps = {
	nodesLoaded: boolean;
	Sites: SitesList;
	MultiSites?: SiteItem | SiteItem[];
	MultiSitesOrigin?: SiteItem[];
	MultiSitesParent?: any;
	MultisiteAlertsAccess?: boolean;
	MultiSitesInstallations?: meteringplanBenchmark[];
	MultisitesCount?: number;
	LabelOfGroup?: string;
};

type NodesStateProps = Omit<NodesContextProps, "LabelOfGroup">;
// export type MultiNodesContextProps = {
//     // nodesLoaded: boolean,
//     Sites: SitesList,
//     // MultiSites?: SiteItem,
// }
// type NodesContext = {
//     NodesContext: NodesContextProps,
//     MultiNodesContext: MultiNodesContextProps,
// }
const initialState: NodesStateProps = {
	nodesLoaded: false,
	Sites: [] as SitesList,
	MultiSites: {} as SiteItem,
	MultiSitesOrigin: [] as SiteItem[],
	MultiSitesParent: "",
};

const NodesContext = createContext(initialState as NodesContextProps);
// const MultiNodesContext = createContext(MultiinitialState)

function SitesProvider({ children }: SitesProviderProps) {
	const { isAuthenticated, doLogout } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const params = useParams();
	const navigate = useNavigate();
	const { user } = useAuth();

	const [nodesState, setNodesState] = useState<NodesContextProps>(initialState);
	const labelOfGroup = useMemo(() => {
		return nodesState.MultiSites &&
			Object.keys(nodesState.MultiSites).length > 0
			? !isArray(nodesState.MultiSites) &&
				(nodesState.MultiSites.type === "customer" ||
					nodesState.MultiSites.type === "site_group") &&
				nodesState.MultiSites.label
				? nodesState.MultiSites.label
				: t`All my sites`
			: "";
	}, [nodesState.MultiSites]);

	const findSiteItemByUuid = (
		items: SiteItem[],
		uuid: string,
	): SiteItem | undefined => {
		let result: SiteItem | undefined;
		items.map((item) => {
			if (item.uuid === uuid) {
				result = item;
				return result;
			} else if (item.type !== "site" && item.children) {
				const childResult = findSiteItemByUuid(
					item.children as SiteItem[],
					uuid,
				);
				if (childResult) {
					result = childResult;
					return;
				}
			} else return null;
		});

		return result;
	};

	const getAccesMultiSite = async (
		founded: SiteItem | undefined,
		type?: string,
	): Promise<{ access: boolean; installations: meteringplanBenchmark[] }> => {
		try {
			if (type === "user" && typeof founded === "string") {
				const response = await getMultisites(founded, "user");
				return {
					access: response.has_alerts_access,
					installations: response.installations,
				};
			} else if (founded && typeof founded !== "string") {
				const response = await getMultisites(founded.uuid, founded.type);
				return {
					access: response.has_alerts_access,
					installations: response.installations,
				};
			} else {
				return { access: false, installations: [] };
			}
		} catch (err) {
			console.error(err);
			throw err;
		}
	};

	const walkTreeCustomer = async (tree: any) => {
		if (params.customer && isAllMySitesFn(params) && tree && user !== null) {
			const sitesCount = returnChild(tree as SiteItem[]).length;
			setNodesState({
				Sites: tree,
				nodesLoaded: true,
				MultiSitesOrigin: tree as SiteItem[],
				MultiSites: tree as SiteItem[],
				MultiSitesParent: "all",
				MultisitesCount: sitesCount,
				MultisiteAlertsAccess: undefined,
			});
		} else {
			if (params.customer && params.customer !== "all" && tree) {
				const founded = findSiteItemByUuid(tree, params.customer);
				if (founded) {
					const sitesCount = returnChild(founded as SiteItem).length;
					setNodesState({
						Sites: tree,
						nodesLoaded: true,
						MultiSitesOrigin: tree as SiteItem[],
						MultiSites: founded as SiteItem,
						MultiSitesParent: founded.uuid,
						MultisitesCount: sitesCount,
						MultisiteAlertsAccess: undefined,
					});
				} else {
					setNodesState({
						Sites: tree,
						nodesLoaded: true,
					});
					// if params.customer is not a group nor customer or if the user is not allowed to view it
					if (tree.length > 0) {
						switch (tree[0].type) {
							case "customer":
							case "site_group":
								navigate(
									goToWithPeriod(
										PATH_DASHBOARD.dashboard_customer,
										tree[0].uuid,
									),
								);
								return;
							case "site":
								navigate(
									generatePath(PATH_DASHBOARD.dashboard_with_node, {
										node: tree[0].uuid,
									}),
								);
								return;
						}
					}
				}
			}
		}
	};

	useEffect(() => {
		if (!isAuthenticated) return;
		try {
			// Smart Impulse rest api
			getAll().then(
				(response) => {
					if (params.customer) {
						walkTreeCustomer(response as any);
					} else {
						setNodesState({
							Sites: response,
							nodesLoaded: true,
							// [response] as any,
						});
					}
				},
				(error_response) => {
					/// - si code 403 -> rediriger vers le formulaire de login -> puis connection
					if (error_response.status === 403) {
						// not supposed to happen with the BroadcastChannel("AuthenticatedStatus"),
						// except on browsers that don't implement BroadcastChannel (mobile browsers except Firefox)
						enqueueSnackbar(t`You have been disconnected from another page.`, {
							variant: "warning",
							autoHideDuration: 15000,
						});
						doLogout().then(() => {});
					}
				},
			);
		} catch (err) {
			console.error(err);
		}
	}, [isAuthenticated]);

	// const [MultiNodeState, setMultiNodeState] = useState<MultiNodesContextProps>(MultiInitialState);

	useEffect(() => {
		if (
			!nodesState.nodesLoaded ||
			nodesState.MultiSitesParent === params.customer
		)
			return;

		// console.log(nodesState.nodesLoaded, nodesState, params.customer, "SiteContext Useffect")

		// find all site uuids, to check that the stored site is in the list
		walkTreeCustomer(nodesState.Sites);
	}, [params.customer, nodesState.nodesLoaded]);

	useEffect(() => {
		const corporateLimit = corporateLimitFn(user?.isSmartImpulse);
		// call /node API only if less than corporateLimit
		if (
			nodesState.nodesLoaded &&
			nodesState.MultisitesCount &&
			nodesState.MultisitesCount < corporateLimit
		) {
			const forUser = isAllMySitesFn(params);
			getAccesMultiSite(
				forUser && user !== null ? user.uuid : nodesState.MultiSites,
				forUser ? "user" : undefined,
			).then((resp) => {
				setNodesState({
					...nodesState,
					MultisiteAlertsAccess: resp.access,
					MultiSitesInstallations: resp.installations,
				});
			});
		}
	}, [nodesState.MultiSites, nodesState.MultisitesCount]);

	return (
		<NodesContext.Provider
			value={{ ...nodesState, LabelOfGroup: labelOfGroup }}
		>
			{children}
		</NodesContext.Provider>
	);
}

export { NodesContext, SitesProvider };
