import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, Link, Stack, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useSnackbar } from "notistack";
import React from "react";
import {
	Controller,
	ControllerRenderProps,
	FieldError,
	useForm,
} from "react-hook-form";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { CustomScrollbar } from "src/components/others/Scrollbar";
import { PATH_PAGE } from "src/routes/paths";
import { updateUser } from "src/services/service";
import { jt, t } from "ttag";
import * as Yup from "yup";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import useAuth from "../../../../hooks/useAuth";

// ----------------------------------------------------------------------

export type FormValuesProps = {
	email: string;
	firstName: string;
	lastName: string;
	jobTitle?: string;
	phoneNumber: string;
	secondaryNumber: string;
};

export default function UserProfile(props: { ButtonLabel?: string }) {
	const { enqueueSnackbar } = useSnackbar();
	const { isAuthenticated, user, updateUserRight } = useAuth();
	const UserProfileTranslations = {
		email: t`Email`,
		phoneNumber: t`Primary phone`,
		secondaryNumber: t`Secondary phone`,
		firstName: t`First name`,
		lastName: t`Last name`,
		jobTitle: t`Job title`,
	};

	const UpdateUserSchema = Yup.object().shape({
		email: Yup.string().email(t`Invalid email`).required(t`email is required`),
		phoneNumber: Yup.string(),
		secondaryNumber: Yup.string(),
		firstName: Yup.string().required(t`First name is required`),
		lastName: Yup.string().required(t`Last name is required`),
		jobTitle: Yup.string(),
	});
	const controllerSignal = new AbortController();

	const defaultValues: FormValuesProps = {
		email: user?.email || "",
		phoneNumber: user?.phoneNumber || "",
		secondaryNumber: user?.secondaryNumber || "",
		firstName: user?.firstName || "",
		lastName: user?.lastName || "",
		jobTitle: user?.jobTitle || "",
	};

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(UpdateUserSchema),
		defaultValues,
		mode: "onBlur",
	});

	const {
		handleSubmit,
		control,
		reset,
		formState: { isDirty, isValid, isSubmitting, errors },
	} = methods;

	const onSubmit = async () => {
		const data = methods.getValues();
		try {
			const { email, ...filteredData } = data;
			updateUser(filteredData, controllerSignal.signal).then(
				(response: any) => {
					if (response && response.errors) {
						enqueueSnackbar(
							t`Update fail : ` + JSON.stringify(response.errors),
							{
								variant: "error",
							},
						);
					} else {
						if (response.status) {
							Object.entries(response.message.response_data).map(
								([key, value]: [string, any]) => {
									if (key in UserProfileTranslations) {
										const message = `${
											UserProfileTranslations[key as keyof FormValuesProps]
										} : ${value.join(", ")}`;
										methods.setError(key as keyof FormValuesProps, {
											type: "manual",
										});
										enqueueSnackbar(message, { variant: "error" });
									} else {
										const message = `${value.join(", ")}`;
										enqueueSnackbar(message, { variant: "error" });
									}
								},
							);
						} else {
							enqueueSnackbar(t`Update Done`, { variant: "success" });
							reset(data);
							const userResponse = JSON.parse(response);
							updateUserRight({ isAuthenticated, user: userResponse });
						}
					}
				},
			);
		} catch (error) {
			console.error(error);
		}
	};

	const MuiPhoneStyled = (
		field:
			| ControllerRenderProps<FormValuesProps, "phoneNumber">
			| ControllerRenderProps<FormValuesProps, "secondaryNumber">,
		label: string,
		defaultCountry: any /*CountryCode*/,
		error?: FieldError,
	) => {
		const [localError, setLocalError] = React.useState<string | null>(null);
		return (
			<MuiTelInput
				MenuProps={{
					PaperProps: {
						sx: {
							mt: 1,
							maxHeight: "30vh",
							...CustomScrollbar(),
						},
					},
					MenuListProps: {
						sx: {
							mt: 1,
							maxHeight: "30vh",
							...CustomScrollbar(),
						},
					},
				}}
				{...field}
				label={label}
				error={!!error || !!localError}
				helperText={error?.message || localError}
				defaultCountry={defaultCountry}
				onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
					const onlyDigits = e.target.value.replace(/\D/g, "");
					setLocalError(null);
					if (onlyDigits.length > 15) {
						e.target.value = onlyDigits.slice(0, 15);
						setLocalError(t`The telephone number must not exceed 15 digits`);
					}
				}}
			/>
		);
	};

	const terms_link = (
		<Link
			target="_blank"
			rel="noopener"
			color="inherit"
			component={RouterLink}
			sx={{ color: "text.disabled" }}
			to={PATH_PAGE.terms}
		>{t`terms`}</Link>
	);
	const privacy_link = (
		<Link
			target="_blank"
			rel="noopener"
			tabIndex={-1}
			color="inherit"
			component={RouterLink}
			sx={{ color: "text.disabled" }}
			to={PATH_PAGE.privacy}
		>{t`privacy policy`}</Link>
	);

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Card sx={{ p: 3 }}>
						<Box
							sx={{
								display: "grid",
								rowGap: 3,
								columnGap: 2,
								gridTemplateColumns: {
									xs: "repeat(1, 1fr)",
									sm: "repeat(2, 1fr)",
								},
							}}
						>
							<RHFTextField
								name="firstName"
								key={"firstName"}
								error={!!errors.firstName}
								label={UserProfileTranslations.firstName}
								controlMethod={control}
								requiredAsterix
							/>
							<RHFTextField
								name="lastName"
								key={"lastName"}
								error={!!errors.lastName}
								label={UserProfileTranslations.lastName}
								controlMethod={control}
								requiredAsterix
							/>
							<RHFTextField
								name="email"
								key={"email"}
								isDisabled={true}
								error={!!errors.email}
								label={UserProfileTranslations.email}
								// controlMethod={control}
								requiredAsterix
							/>
							<RHFTextField
								name="jobTitle"
								key={"jobTitle"}
								label={UserProfileTranslations.jobTitle}
								requiredAsterix={false}
							/>

							<Controller
								name="phoneNumber"
								control={control}
								key={"phoneNumber"}
								render={({ field }) =>
									MuiPhoneStyled(
										field,
										UserProfileTranslations.phoneNumber,
										"FR",
										errors.phoneNumber,
									)
								}
							/>

							<Controller
								name="secondaryNumber"
								control={control}
								key={"secondaryNumber"}
								render={({ field }) =>
									MuiPhoneStyled(
										field,
										UserProfileTranslations.secondaryNumber,
										"FR",
										errors.secondaryNumber,
									)
								}
							/>
						</Box>
						<Box alignItems="flex-start">
							{props.ButtonLabel && (
								<Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
									<Typography
										variant="caption"
										fontWeight={700}
										component="div"
										sx={{
											color: "text.disabled",
											alignContent: "baseline",
											", & *": {
												alignContent: "baseline",
											},
										}}
									>
										{jt`By registering, I accept the ${terms_link} and the ${privacy_link}.`}
									</Typography>
								</Stack>
							)}
							<Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
								<LoadingButton
									fullWidth={!!props.ButtonLabel}
									type="submit"
									variant="contained"
									loading={isSubmitting}
									disabled={!isValid || !isDirty}
								>
									{props.ButtonLabel ? props.ButtonLabel : t`Save`}
								</LoadingButton>
							</Stack>
						</Box>
					</Card>
				</Grid>
			</Grid>
		</FormProvider>
	);
}
