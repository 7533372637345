import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
	Box,
	Button,
	Container,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { StyledDrawerWithHandle } from "src/components/dashboard/MagicButton/layout/layoutMagicButton";
import { CustomScrollbar } from "src/components/others/Scrollbar";
import { StyledDialog } from "src/components/others/StyledDialog";
// routes
import { PATH_AUTH } from "src/routes/paths";
// assets
import { t } from "ttag";
// components
import Page from "../../components/others/Page";
// sections
import { DefinePasswordForm } from "../../sections/auth/define-password";
// layouts
import LogoOnlyLayout from "../LogoOnlyLayout";

// ----------------------------------------------------------------------

export default function OnBoarding(props: {
	OnboardingJSX: JSX.Element;
	open: boolean;
	handleOpen: () => void;
	handleClose: () => void;
}) {
	// const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { OnboardingJSX, open, handleClose, handleOpen } = props;

	return (
		<>
			{isMobile ? (
				<StyledDrawerWithHandle
					anchor="bottom"
					open={open}
					onOpen={handleOpen}
					onClose={handleClose}
					PaperProps={{ sx: { ...CustomScrollbar() } }}
				>
					<Box sx={{ width: "100%", maxWidth: "100%" }}>{OnboardingJSX}</Box>
				</StyledDrawerWithHandle>
			) : (
				<StyledDialog
					maxWidth={"sm"}
					transitionDuration={0}
					fullWidth={true}
					open={open}
					onClose={handleClose}
					PaperProps={{ sx: { ...CustomScrollbar() } }}
				>
					<Box sx={{ width: "100%", maxWidth: "100%" }}>{OnboardingJSX}</Box>
				</StyledDialog>
			)}
		</>
	);
}
