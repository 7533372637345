import { Box, Card, Grid, Link, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { AuthUser, AuthUserType } from "src/@types/auth";
import Scrollbar from "src/components/others/Scrollbar";
import { t } from "ttag";
import { ContentStyle, MainStyle } from "./MainContent";
import SmartImpulseFooter from "./footer/Footer";
import DashboardHeader from "./header/Header";
import LanguagePopover from "./header/LanguagePopover";
import OnBoarding from "./onBoarding";

type OnboardingUser = {
	user: Record<string, any> | AuthUserType;
	collapseClick: boolean;
	isCollapse: boolean;
	collapsehover: boolean;
	children: JSX.Element;
};

export default function OnboardingUserProfileJSX(props: OnboardingUser) {
	const { user, collapseClick, collapsehover, isCollapse, children } = props;
	const [open, setOpen] = useState(true);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		if (user && !user.needsOnboarding) setOpen(false);
	};
	return (
		<>
			<DashboardHeader
				isCollapse={isCollapse}
				onOpenSidebar={() => setOpen(true)}
				outsideSiteContext={true}
			/>
			<ContentStyle>
				<MainStyle
					collapseClick={collapseClick}
					collapsehover={collapsehover ? Boolean(collapsehover) : undefined}
				>
					<Scrollbar
						sx={{
							height: 1,
							"& .simplebar-content": {
								height: 1,
								display: "flex",
								flexDirection: "column",
							},
						}}
					>
						<OnBoarding
							OnboardingJSX={
								<Box sx={{ flexGrow: 1 }}>
									<Stack
										direction="row"
										alignItems="center"
										sx={{ pt: 3, pl: 3, pr: 3 }}
									>
										<Box sx={{ flexGrow: 1 }}>
											<Typography variant="h4">
												{t`Welcome to your Dashboard!`}
											</Typography>
											<Typography
												variant="body1"
												sx={{ color: "text.secondary" }}
											>
												{t`Enter your information below.`}
											</Typography>
										</Box>
										<Box sx={{ justifyContent: "center" }}>
											<LanguagePopover />
										</Box>
									</Stack>
									{children}
								</Box>
							}
							open={open}
							handleOpen={handleOpen}
							handleClose={handleClose}
						/>
						<Outlet />
						<Box sx={{ flexGrow: 1 }} />
						<SmartImpulseFooter />
					</Scrollbar>
				</MainStyle>
			</ContentStyle>
		</>
	);
}
