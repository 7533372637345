import { useEffect, useState } from "react";
// import { SmartCircularProgress } from 'src/layouts/dashboard/loader/LoaderSmart';
import SmartLoader from "src/components/others/Loaders";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { t } from "ttag";

import { Box } from "@mui/material";
import { isArray, isEqual } from "lodash";
import { useParams } from "react-router";
import type { SiteItem } from "src/@types/sites";
import { isAllMySitesFn } from "src/utils/constants";
import {
	HeaderButton,
	MagicContainer,
} from "../MagicButton/layout/layoutMagicButton";
import { SitesSelector } from "../MagicButton/layout/treeComponents";
// import { SitesSelector } from '../MagicButton/layout/treeComponentsRework';

export default function SiteSelector() {
	const { Sites, nodesLoaded, MultiSites } = useNodesContext();
	const { Site, siteLoading } = useSiteContext();
	const [currentSiteLabel, setCurrentSiteLabel] = useState("");
	const [loadingText, setLoadingText] = useState(false);

	const params = useParams();
	useEffect(() => {
		if (!nodesLoaded) return;
		// if (params.customer && MultiSites === undefined) return
		if (
			(params.customer &&
				MultiSites &&
				!isEqual(currentSiteLabel, (MultiSites as SiteItem).label)) ||
			(params.node && Site && !isEqual(currentSiteLabel, Site?.label))
		) {
			// if (params.node && MultiSites && currentSiteLabel && currentSiteLabel === (MultiSites as SiteItem)?.label) return
			if (
				(isAllMySitesFn(params) && isArray(MultiSites)) ||
				(!isArray(MultiSites) && params.customer === MultiSites?.uuid) ||
				params.node === Site?.uuid
			) {
				const LabelofGroup =
					params.customer && params.node === undefined
						? MultiSites &&
							!isArray(MultiSites) &&
							(MultiSites.type === "customer" ||
								MultiSites.type === "site_group") &&
							MultiSites.label
							? MultiSites.label
							: t`All my sites`
						: Site?.label || "";
				// console.log(LabelofGroup, MultiSites, siteLoading, Site?.label, params.node, params.customer, 'setCurrentSiteLabel')
				setLoadingText(true);
				setCurrentSiteLabel(LabelofGroup);
				// params.customer !== undefined ? setCurrentSiteLabel(LabelofGroup) : setCurrentSiteLabel(Site?.label || '')
			}
		}
	}, [Site, MultiSites, nodesLoaded, params.node, params.customer]);

	useEffect(() => {
		// console.log(params.node, params.customer, MultiSites, Site, currentSiteLabel, loadingText, 'setLoadingText')
		const LabelofGroup =
			params.customer && params.node === undefined
				? MultiSites &&
					!isArray(MultiSites) &&
					(MultiSites.type === "customer" ||
						MultiSites.type === "site_group") &&
					MultiSites.label
					? MultiSites.label
					: t`All my sites`
				: Site?.label || "";

		if (currentSiteLabel !== "" && currentSiteLabel === LabelofGroup) {
			// console.log(currentSiteLabel, siteLoading, Site?.label, params.node, params.customer, 'setCurrentSiteLabel')
			setLoadingText(false);
		}
	}, [currentSiteLabel]);

	if (nodesLoaded && Sites !== undefined && Sites.length === 0) {
		return (
			<HeaderButton
				handleClick={() => {}}
				value={t`No sites`}
				noEndIcon={true}
			/>
		);
	}

	return (
		<MagicContainer
			icon="ph:buildings-duotone"
			id="nodeSelector"
			header={true}
			value={
				currentSiteLabel !== "" && !siteLoading && !loadingText ? (
					<>
						{currentSiteLabel}
						{siteLoading && (
							<span
								style={{
									display: "inline-block",
									width: "50px",
									height: "20px",
									position: "relative",
									margin: "auto",
								}}
							>
								<SmartLoader />
							</span>
						)}
						{/* {loadingText && <span style={{ display: 'inline-block', width: "50px", height: "20px", position: "relative", margin: "auto" }}><SmartLoader /></span>} */}
					</>
				) : (
					<span
						style={{
							display: "inline-block",
							width: "50px",
							height: "20px",
							position: "relative",
							margin: "auto",
						}}
					>
						<SmartLoader />
					</span>
				)
			}
		>
			<SitesSelector setSelectedText={setCurrentSiteLabel} />
		</MagicContainer>
	);
}
