import { Typography } from "@mui/material";
import { ContactLink } from "src/utils/smoochSmart";
import { t } from "ttag";

export const contactLinkTchat = (
	<ContactLink
		component="button"
		variant={"body2"}
		sx={{ display: "contents", verticalAlign: "baseline" }}
	>
		<Typography
			variant="body2"
			sx={{
				display: "contents",
				fontWeight: "bold",
				textDecoration: "underline",
			}}
		>
			{t`Contact Smart Impulse.`}
		</Typography>
	</ContactLink>
);
