import { cp } from "fs";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { TourProvider } from "@reactour/tour";
import { isArray } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import AppTourContextProvider from "src/contexts/AppTourContext";
import { findInstallation } from "src/contexts/magicButtonContext";
import useAppTour from "src/hooks/useAppTourContext";
import useAuth from "src/hooks/useAuth";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import NoSites from "src/pages/dashboard/empty/NoSites";
import UserProfile from "src/sections/@dashboard/user/account/UserProfile";
import { createPageView, getVersionFromAPi } from "src/services/service";
import { checkInterval, versionStorage } from "src/utils/checkVersion";
import { useLazyEffect } from "src/utils/useLazyEffect";
import { t } from "ttag";
import Scrollbar from "../../components/others/Scrollbar";
import { HEADER, NAVBAR } from "../../config";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import OnboardingUserProfileJSX from "./OnboardingUserProfile";
import SmartImpulseFooter from "./footer/Footer";
import DashboardHeader from "./header/Header";
import LanguagePopover from "./header/LanguagePopover";
import NavbarVertical from "./navbar/NavbarVertical";

// ----------------------------------------------------------------------

export type MainStyleProps = {
	collapseClick: boolean;
	collapsehover: boolean | undefined;
};
// const RootStyle = styled("div")({
// 	display: "flex !important ",
// 	height: "100%",
// 	width: "100%",
// 	// overflow: 'hidden',
// });
export const ContentStyle = styled("div")(() => ({
	width: "100%",
	display: "inline-flex",
	// flexDirection: 'row',
	// alignSelf: 'auto'
}));

export const MainStyle = styled("main", {
	shouldForwardProp: (prop) => prop !== "collapseClick",
})<MainStyleProps>(({ collapseClick, collapsehover, theme }) => ({
	flexGrow: 1,
	overflow: "auto",
	// height: '100%',
	// top: HEADER.MOBILE_HEIGHT + 20,
	marginTop: HEADER.DESKTOP_HEIGHT + 20,
	// marginBottom: "20px",
	// paddingBottom: HEADER.MOBILE_HEIGHT + 24,
	// background: "red",
	[theme.breakpoints.up("sm")]: {
		// top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
		marginTop: HEADER.DESKTOP_HEIGHT + 24,
		// height: '100%',
		// minHeight: '100%',
		marginLeft: "20px",
		marginRight: "20px",
		// width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 24}px)`,
		// transition: theme.transitions.create('margin-left', {
		//   duration: theme.transitions.duration.shorter,
		// }),
		...(collapseClick && {
			marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 20,
		}),
		...(collapsehover && {
			marginLeft: NAVBAR.DASHBOARD_WIDTH,
		}),
	},
	[theme.breakpoints.between("sm", "lg")]: {
		// marginLeft: "20px",
		marginLeft: "0",
		marginRight: "0",
	},
	[theme.breakpoints.down("sm")]: {
		// marginLeft: "20px",
		marginTop: HEADER.MOBILE_HEIGHT + 24,
	},
}));

// ----------------------------------------------------------------------

export default function DashboardLayout(props: {
	outsideSiteContext?: boolean;
}) {
	const { collapseClick, isCollapse, collapsehover } = useCollapseDrawer();
	const [checkIfUpateAvailable, setCheckIfUpateAvailable] = useState(true);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		versionStorage(setCheckIfUpateAvailable, checkIfUpateAvailable);
		checkInterval(setCheckIfUpateAvailable, checkIfUpateAvailable);
	}, []);

	const action = (snackbarId: any) => (
		<>
			<Button
				onClick={() => {
					window.location.reload();
				}}
			>
				{t`Reload now`}
			</Button>
			<IconButton
				aria-label="close"
				size="small"
				onClick={() => {
					closeSnackbar(snackbarId);
				}}
			>
				<CloseRoundedIcon />
			</IconButton>
		</>
	);

	useEffect(() => {
		getVersionFromAPi().then((data: string) => {
			// console.log(process.env.REACT_APP_GIT_SHA, data, "version");
			const version = data.trim();
			// console.log(version, process.env.REACT_APP_GIT_SHA, 'version not');
			if (version && version !== process.env.REACT_APP_GIT_SHA) {
				enqueueSnackbar(t`A new version is available. `, {
					variant: "info",
					action,
					autoHideDuration: null,
				});
			}
		});
	}, [checkIfUpateAvailable]);

	// const { themeLayout } = useSettings();
	const params = useParams();
	const location = useLocation();
	const { Site, siteLoading } = useSiteContext();
	// const isDesktop = useResponsive('up', 'lg');

	const [open, setOpen] = useState(false);
	// const activeSmooch = true
	// const verticalLayout = themeLayout === 'vertical';
	const theme = useTheme();
	const { user } = useAuth();
	const [loaded, setLoaded] = useState(true);
	// const { setIsOpen, isOpen } = useTour()
	const { triggerTour } = useAppTour();
	useEffect(() => {
		if (!props.outsideSiteContext && loaded) {
			setLoaded(!loaded);
			if (user?.needsTour) {
				triggerTour();
			}
		}
	}, [loaded, user]);

	useLazyEffect(
		() => {
			if (
				params.customer !== undefined ||
				(!siteLoading && Site !== undefined)
			) {
				createPageView().then(
					() => {},
					() => {},
				);
			}
			if (siteLoading || Site === undefined) return;

			if (
				params.customer === undefined &&
				params.node &&
				params.installation !== undefined &&
				Site
			) {
				// check that it is a valid installation, otherwise there will be a redirect to a valid installation
				// (could be the special 'default' value, or a typo in the uuid)
				const installationObj = findInstallation(Site, params.installation);
				// console.log(installationObj,params, 'installationObj')
				// const filter_access = (installation: meteringplanBaseObject) => (installation.has_children_with_user_access || (installation.user_access && installation.date_begin_customer !== null))
				if (!installationObj) return;
				// const childrenacces = filter_access(installationObj)
				// console.log(childrenacces, installationObj, '[APPTRACKER childrenacces]')

				createPageView("installation", params.installation).then(
					() => {},
					() => {},
				);
			} else if (params.node !== undefined && params.node !== "default-site") {
				createPageView("site", params.node).then(
					() => {},
					() => {},
				);
			}
		},
		[Site?.uuid, Site?.default_installation, location.pathname],
		250,
	);

	return (
		<TourProvider
			steps={[]}
			scrollSmooth={true}
			showNavigation
			showDots
			showPrevNextButtons
			showCloseButton
			showBadge
			styles={{
				popover: (base) => ({
					...base,
					backgroundColor: theme.palette.background.paper,
					"--reactour-accent": theme.palette.primary.main,
					borderRadius: 16,
					maxWidth: "90vw",
					maxHeight: "90vh",
				}),
				maskWrapper: (base) => ({
					...base,
					// color: theme.palette.primary.light,
				}),
			}}
		>
			<AppTourContextProvider>
				<Box
					id="mainContent"
					sx={{
						// display: { lg: 'flex' },
						display: "flex",
						minHeight: "100%",
						// overflow: 'hidden',
						height: "100%",
					}}
				>
					{user?.needsOnboarding && !user.hijacked ? (
						<OnboardingUserProfileJSX
							children={<UserProfile ButtonLabel={t`Next`} />}
							collapseClick={collapseClick}
							collapsehover={collapsehover}
							isCollapse={collapsehover}
							user={user}
						/>
					) : (
						<>
							<DashboardHeader
								isCollapse={isCollapse}
								onOpenSidebar={() => setOpen(true)}
								outsideSiteContext={props.outsideSiteContext}
							/>
							<ContentStyle>
								<NavbarVertical
									isOpenSidebar={open}
									onCloseSidebar={() => setOpen(false)}
								/>
								<MainStyle
									collapseClick={collapseClick}
									collapsehover={
										collapsehover ? Boolean(collapsehover) : undefined
									}
								>
									<Scrollbar
										sx={{
											height: 1,
											"& .simplebar-content": {
												height: 1,
												display: "flex",
												flexDirection: "column",
											},
										}}
									>
										<Outlet />
										<Box sx={{ flexGrow: 1 }} />
										<SmartImpulseFooter />
									</Scrollbar>
								</MainStyle>
							</ContentStyle>
						</>
					)}
				</Box>
			</AppTourContextProvider>
		</TourProvider>
	);
}
