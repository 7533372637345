import { useTour } from "@reactour/tour";
import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	createContext,
	useEffect,
	useState,
} from "react";
import useAuth from "src/hooks/useAuth";
import useNodesContext from "src/hooks/useNodesContext";
import { appTour } from "src/utils/appTour";
import {
	MULTISITE_DATE,
	updatesTourMultisite,
} from "src/utils/updatesTourMultisite";

type AppTourProviderProps = { children: ReactNode };

export type AppTourContextProps = {
	openTour: boolean;
	// isOpen:Boolean,
	setOpenTour: Dispatch<SetStateAction<boolean>>;
	// setIsOpen: Dispatch<SetStateAction<Boolean>>,
	// handleIntroTour: () => void,
	setTourType: (type: "default" | "updates" | null) => void;
	triggerTour: () => void;
};

const initialState: AppTourContextProps = {
	openTour: false,
	// isOpen:false,
	setOpenTour: () => {},
	// setIsOpen:()=>{},
	// handleIntroTour: () => { },
	setTourType: () => {},
	triggerTour: () => {},
};

const AppTourContext = createContext(initialState);

// let firstLoad = true

export default function AppTourContextProvider({
	children,
}: AppTourProviderProps) {
	// const { isOpen, setCurrentStep, steps, setIsOpen } = useTour()
	const [openTour, setOpenTour] = useState(false);
	const [tourType, setTourType] = useState<"default" | "updates" | null>(null);
	const { isOpen, setIsOpen, setSteps, setCurrentStep } = useTour();
	const { user, isAuthenticated } = useAuth();
	const Steps = appTour();
	const updateStepsMultisite = updatesTourMultisite();
	const { nodesLoaded, Sites } = useNodesContext();

	useEffect(() => {
		if (!isAuthenticated) return;

		if (openTour || (user?.needsTour && !user?.needsOnboarding)) {
			setTourType("default");
		}
		if (tourType && user?.lastTourDate < MULTISITE_DATE) {
			setTourType("updates");
		}
	}, [isAuthenticated, user, openTour]);

	useEffect(() => {
		switch (tourType) {
			case "default":
				setSteps(Steps);
				triggerTour();
				break;
			case "updates":
				if (nodesLoaded && Sites.length > 2) {
					setSteps(updateStepsMultisite);
					triggerTour();
				}
				break;
			case null:
				setSteps([]);
				break;
		}
	}, [tourType, nodesLoaded, Sites]);

	useEffect(() => {
		if (!isOpen) setCurrentStep(0);
	}, [isOpen]);

	const triggerTour = () => {
		setIsOpen(true);
	};

	return (
		<AppTourContext.Provider
			value={{
				openTour,
				// isOpen,
				setOpenTour,
				// setIsOpen,
				// handleIntroTour,
				setTourType,
				triggerTour,
			}}
		>
			{children}
		</AppTourContext.Provider>
	);
}

export { AppTourContext, AppTourContextProvider };
